<template>
  <div>
    <a-table
      :columns="approvalHistoryListColumns"
      :dataSource="approvalHistoryList"
      :pagination="pagination"
      :bordered="true"
      :row-key="(record) => record.skuId"
    >
    </a-table>
  </div>
</template>
<script>

export default {
  name: 'ApprovalHistoryListEdit',
  props: {
    approvalHistoryList: {
      type: Array
    }
  },
  data () {
    return {
      pagination: { showSizeChanger: true, showQuickJumper: true, pageSize: 20, total: this.approvalHistoryList.length },
      approvalHistoryListColumns: [
        {
          defaultTitle: '审批人',
          title: this.$t('columnName.approverName'),
          dataIndex: 'approverName',
          key: 'approverName',
          align: 'left'
        },
        {
          defaultTitle: 'approvalTime',
          title: this.$t('columnName.approvalTime'),
          dataIndex: 'approvalTime',
          key: 'approvalTime',
          align: 'center',
          customRender: (text) => (this.formatDateTime(text))
        },
        {
          defaultTitle: '审批结果',
          title: this.$t('columnName.approvalStatus'),
          dataIndex: 'approvalStatus',
          key: 'approvalStatus',
          align: 'center'
        },
        {
          defaultTitle: '备注',
          title: this.$t('columnName.approvalComment'),
          dataIndex: 'approvalComment',
          key: 'approvalComment'
        }
      ]
    }
  },
  mounted () {
  },
  methods: {
    // 新增sku弹框
    handleShowSkuSelect () {
      console.debug('handleShowSkuSelect', this.condition)
      this.$refs.selectSku.handleSelectSkuShow()
    },
    // 确认sku弹框
    handleSkuSelectedOk (selectedSku) {
      this.approvalHistoryList.push(selectedSku)
      // 弹出编辑页面
      this.skuModalShow = false
      console.log('handleSkuSelectedOk', this.approvalHistoryList)
    },
    onDeleteSku (skuId) {
      const list = this.approvalHistoryList.filter(item => item.skuId !== skuId)
      this.approvalHistoryList.splice(0, this.approvalHistoryList.length)
      list.forEach(item => {
        this.approvalHistoryList.push(item)
      })
    }
  },
  watch: {
    approvalHistoryList (approvalHistoryList) {
      if (approvalHistoryList) {
        this.pagination.size = approvalHistoryList.length
      }
    }

  }
}
</script>
<style>
.ant-select {
  width: 100%;
}
</style>
