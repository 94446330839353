<template>
  <div>
    <a-card :title="this.$t('title.'+fieldName)">
      <div slot="extra" href="#">
        <a-button v-if="editable" icon="plus" style="float:right" type="link" @click="handleValueAdd()">
          {{ $t('button.add') }}
        </a-button>
      </div>
      <a-table
        v-if="valueList && valueList.length>0"
        :columns="valueListColumns"
        :dataSource="valueList"
        :pagination="false"
        :bordered="true"
        :scroll="{ x: '80%' }"
        :row-key="(record) => record.value"
      >
        <template slot="action" slot-scope="text, record">
          <a-popconfirm v-if="editable" :title="$t('msg.confirmDelete')" @confirm="() => onDeleteValue(record.value)">
            <a href="javascript:;">{{ $t('button.delete') }}</a>
          </a-popconfirm>
        </template>
      </a-table>
      <a-modal
        :confirm-loading="false"
        :title="$t('page.'+fieldName)"
        :visible="valueModalShow"
        @cancel="handleValueEditCancel"
        @ok="handleValueSelectedOk"
      >
        <a-form-item
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-textarea
            v-decorator="[
              'data.homeTemplateName',
              {
                rules: [
                  { max: 100, message: this.$t('field.homeTemplateName') + '长度小于等于100' },
                ],
              },
            ]"
            v-model="value"
            :placeholder="'请输入' + this.$t('field.'+fieldName)"
          />
        </a-form-item>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
export default {
  name: 'ValueListEdit',
  props: {
    fieldName: {
      type: String
    },
    valueList: {
      type: Array
    },
    editable: {
      type: Boolean
    }
  },
  components: {},
  data () {
    return {
      value: '',
      valueModalShow: false,
      valueListColumns: [
        {
          defaultTitle: '列表图片URL',
          title: this.$t('columnName.' + this.fieldName),
          dataIndex: 'value',
          key: 'value',
          align: 'left',
          width: '80%'
        },
        {
          defaultTitle: '操作',
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          align: 'left',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ]
    }
  },
  mounted () {
  },
  methods: {
    // 新增value弹框
    handleValueAdd () {
      this.value = ''
      // 弹出编辑页面
      this.valueModalShow = true
    },
    // 关闭sku弹框
    handleValueEditCancel () {
      // 弹出编辑页面
      this.valueModalShow = false
    },
    // 确认value弹框
    handleValueSelectedOk () {
      this.valueList.push({ value: this.value })
      console.log('handleValueSelectedOk', this.valueList)
      // 弹出编辑页面
      this.valueModalShow = false
    },
    onDeleteValue (value) {
      const list = this.valueList.filter(item => item.value !== value)
      this.valueList.splice(0, this.valueList.length)
      list.forEach(item => {
        this.valueList.push(item)
      })
    }
  }
}
</script>
<style>
.ant-select {
  width: 100%;
}
</style>
