<template>
  <page-header-wrapper>
    <div class="a-card">
      <HomeTemplateSearch :queryParam="queryParam" @handleAdd="handleAdd" />
      <div class="table-operator">
        <a-button style="display:none" type="dashed" @click="tableOption">
          {{ (optionAlertShow && $t('button.close')) || $t('button.open') }} alert
        </a-button>
        <a-dropdown v-if="selectedRowKeys.length > 0" v-action:edit>
          <a-menu slot="overlay">
            <a-menu-item key="1" class="hide" click="handleDeleteSelected">
              <a-icon type="delete" />
              {{ $t('button.delete') }}
            </a-menu-item>
            <!-- lock | unlock -->
          </a-menu>
          <a-button style="margin-left: 8px">
            {{ $t('button.batchOperate') }}
            <a-icon type="down" />
          </a-button>
        </a-dropdown>
      </div>
      <s-table
        :bordered="true"
        ref="table"
        :alert="options.alert"
        :data="loadData"
        :dataSource="data"
        :pagination="{ pageSize: 10, pageNo: 1 }"
        :rowSelection="options.rowSelection"
        :scroll="{ x: true }"
        :row-key="(record, index) => index"
      >
        <a-table-column title="操作" align="center">
          <span slot-scope="record">
            <!--          <a vv-if="$auth('table.spu.detail')" @click="handleDetail(record)">{{ $t("button.detail") }}</a>-->
          <!--          <a-divider type="vertical"/>-->
            <a vv-if="$auth('table.homeTemplate.edit')" @click="handleEdit(record)">{{ $t('button.edit') }}</a>
            <a-divider type="vertical" />
            <a-dropdown>
              <a class="ant-dropdown-link"> 更多 <a-icon type="down" /> </a>
              <a-menu slot="overlay">
                <a-menu-item v-if="record.homeTemplateStatus !== '已发布' && record.approvalStatus === '已通过'">
                  <a @click="handlePublish(record)">{{ $t('button.publish') }}</a>
                </a-menu-item>
                <a-menu-item v-if="record.approvalStatus !== '已通过'">
                  <a @click="handleApproval(record)">{{ $t('button.approval') }}</a>
                </a-menu-item>

                <a-menu-item v-if="record.status !== 1">
                  <a @click="handleDisable(record)">{{ $t('button.disable') }}</a>
                </a-menu-item>
                <a-menu-item v-if="record.status === 0">
                  <a @click="handleEnable(record)">{{ $t('button.enable') }}</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table-column>
         <!-- 首页模板编号 -->
        <a-table-column :title="$t('columnName.homeTemplateNo')" align="left">
          <template slot-scope="record">{{record.homeTemplateNo}}</template>
        </a-table-column>
         <!-- 首页模板名称 -->
        <a-table-column :title="$t('columnName.homeTemplateName')" align="left">
          <template slot-scope="record">{{record.homeTemplateName}}</template>
        </a-table-column>
        <!-- 市场编码 -->
        <a-table-column :title="$t('columnName.marketplaceCode')" align="left">
          <template slot-scope="record">{{ record.marketplaceCode }} - {{ record.marketplaceName }}</template>
        </a-table-column>
        <!-- 语言 -->
        <a-table-column :title="$t('columnName.language')" align="left">
          <template slot-scope="record">{{getCode('languageName', record.language)}}</template>
        </a-table-column>
        <!-- 首页模板状态 -->
        <a-table-column :title="$t('columnName.homeTemplateStatus')" align="left">
          <template slot-scope="record">{{getCode('homeTemplateStatus', record.homeTemplateStatus)}}</template>
        </a-table-column>
        <!-- 审批状态 -->
        <a-table-column :title="$t('columnName.approvalStatus')" align="left">
          <!-- <template slot-scope="record">{{getCode('approvalStatus', record.approvalStatus)}}</template> -->
          <template slot-scope="record">{{record.approvalStatus}}</template>
        </a-table-column>
        <!-- 创建人 -->
        <a-table-column :title="$t('columnName.creatorName')" align="left">
          <template slot-scope="record">{{record.creatorName}}<br />{{formatDateTime(record.createTime)}}</template>
        </a-table-column>
        <!-- 更新人 -->
        <a-table-column :title="$t('columnName.updaterName')" align="left">
          <template slot-scope="record">{{record.updaterName}}<br />{{formatDateTime(record.updateTime)}}</template>
        </a-table-column>

      </s-table>
      <CommentEditor ref="commentEditor" @handleOk="handleCommentEditOk" />
      <CommentEditor ref="publishEditor" @handleOk="handlePublishEditOk" />
      <ApprovalEditor ref="approvalEditor" @handleOk="handleApprovalEditOk" />
      <a-drawer
        :closable="false"
        :visible="showDetailDrawer"
        placement="right"
        width="70%"
        @close="onDetailDrawerClose"
      >
        <HomeTemplateDetail ref="homeTemplateDetail" :options="detailOptions"></HomeTemplateDetail>
      </a-drawer>
    </div>
  </page-header-wrapper>
</template>

<script>
// 演示如何使用 this.$dialog 封装 modal 组件
import {
  approvalHomeTemplate,
  disableHomeTemplate,
  enableHomeTemplate,
  publishHomeTemplate,
  searchHomeTemplate
} from '@/api/cms/homeTemplate'
import moment from 'moment'
import { STable } from '@/components'

import HomeTemplateSearch from '@/views/cms/HomeTemplateSearch'

import CommentEditor from '@/components/comment/CommentEditor'
import ApprovalEditor from '@/components/approval/ApprovalEditor'
import HomeTemplateDetail from '@/views/cms/HomeTemplateDetail'

export default {
  name: 'HomeTemplateList',
  components: {
    STable,
    CommentEditor,
    ApprovalEditor,
    HomeTemplateSearch,
    HomeTemplateDetail
  },
  data() {
    return {
      confirmModalLoading: false,
      // 查询参数
      queryParam: {},
      data: [],
      status: 'all',
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   console.log('loadData.parameter', parameter)
      //   return getHomeTemplate(Object.assign(parameter, this.queryParam))
      //       .then(res => {
      //         return res.result
      //       })
      // },
      selectedRowKeys: [],
      selectedRows: [],
      homeTemplateModalShow: false,
      homeTemplateData: {},
      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false,
      detailOptions: {},
      showDetailDrawer: false
    }
  },
  created() {
    this.tableOption()
    // getRoleList({t: new Date()})
  },
  mounted() {},
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      return searchHomeTemplate(Object.assign(parameter, this.queryParam)).then(res => {
        console.log('loadData->searchHomeTemplate.parameter', parameter, res)
        return res
      })
    },
    tableOption() {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd(record) {
      // 跳转到编辑页面
      this.$router.push({
        path: '/cms/HomeTemplateEdit',
        name: 'HomeTemplateEdit',
        query: {
          type: 'add'
        }
      })
    },
    handleEdit(record) {
      // 跳转到编辑页面
      this.$router.push({
        path: '/cms/HomeTemplateEdit',
        name: 'HomeTemplateEdit',
        query: {
          type: 'edit',
          homeTemplateId: record.homeTemplateId
        }
      })
    },
    handleDetail(record) {
      this.detailOptions = {
        homeTemplateId: record.homeTemplateId
      }
      this.showDetailDrawer = true
      this.$nextTick(() => {
        this.$refs.homeTemplateDetail.loadData(this.detailOptions)
      })
    },
    onDetailDrawerClose() {
      this.showDetailDrawer = false
    },
    handlePublish(record) {
      this.$refs['publishEditor'].show(record, 'publish')
    },
    handlePublishEditOk(params) {
      const record = params.record
      const that = this
      record.homeTemplateStatus = '已发布'
      if (params.type === 'publish') {
        return publishHomeTemplate(record)
          .then(res => {
            console.log('handlePublish', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('发布成功')
            return res
          })
          .catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('发布失败')
          })
      }
    },
    handleCommentEditOk(params) {
      const record = params.record
      const that = this
      if (params.type === 'disable') {
        return disableHomeTemplate(record)
          .then(res => {
            console.log('handleDisable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('锁定成功')
            return res
          })
          .catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      } else if (params.type === 'enable') {
        return enableHomeTemplate(record)
          .then(res => {
            console.log('handleEnable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('解锁定成功')
            return res
          })
          .catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      }
    },
    handleDisable(record) {
      this.$refs['commentEditor'].show(record, 'disable')
    },
    handleEnable(record) {
      this.$refs['commentEditor'].show(record, 'enable')
    },

    handleApproval(record) {
      this.$refs['approvalEditor'].show(record)
    },
    handleApprovalEditOk({type, record}) {
      console.log(record)
      return approvalHomeTemplate({
        approvalComment: record.comment,
        approvalInfo: record.comment,
        approvalStatus: record.approvalType === 'reject' ? '已拒绝' : '已通过' /** 待审核, 已通过, 已拒绝, 审核中 */,
        homeTemplateId: record.homeTemplateId
      })
      .then(res => {
        this.$refs.table.refresh(true)
        this.$message.success('审批成功')
        return res
      })
      .catch(() => {
        this.$refs.table.refresh(true)
        this.$message.success('审批失败')
      })
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, 0.45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;

  span {
    line-height: 20px;
  }

  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
