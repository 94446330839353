<template>
  <!-- 两步验证 -->
  <a-modal
    centered
    :title="$t('page.approvalEditor')"
    v-model="visible"
    @ok="handleOk"
    @cancel="handleCancel"
    :maskClosable="false"
  >
    <a-textarea placeholder="请输入备注" :rows="4" v-model="comment"/>
    <a-radio-group v-model="approvalType">
      <a-radio v-for="(item, index) in codeList.approvalType" :key="index" :value="item.value">
        {{ item.label }}
      </a-radio>
    </a-radio-group>
  </a-modal>
</template>

<script>
export default {
  data () {
    return {
      codeList: {
        // approvalType: [{ label: '通过', value: 'accept' }, { label: '驳回', value: 'reject' }]
        approvalType: [{ label: '通过', value: 'approve' }, { label: '驳回', value: 'reject' }]
      },
      approvalType: '',
      visible: false,
      comment: ''
    }
  },
  methods: {
    show (record) {
      this.comment = ''
      this.approvalType = ''
      this.record = JSON.parse(JSON.stringify(record))
      this.visible = true
      console.log('approvalEditorShow', this.record, this.type)
    },
    handleOk () {
      if(this.approvalType == '') {
        return this.$message.warning('请选择审批结果！')
      }
      this.record[this.approvalType + 'Comment'] = this.comment
      this.record.approvalType = this.approvalType
      this.record.approvalStatus = this.approvalType
      this.record.comment = this.comment
      this.visible = false
      this.$emit('handleOk', { type: this.approvalType, record: this.record })
    },
    handleCancel () {
      this.visible = false
      this.$emit('cancel')
    },
    onForgeStepCode () {

    }
  }
}
</script>
<style lang="less" scoped>
.step-form-wrapper {
  margin: 0 auto;
  width: 80%;
  max-width: 400px;
}
</style>
