<template>
  <a-card :title="$t('field.'+fieldName)">
    <a-upload
      :before-upload="beforeUploadPicDisplayList"
      :customRequest="httpRequestPicDisplayList"
      :file-list="fileList"
      :multiple="true"
      list-type="picture-card"
      @change="handlePicDisplayListChange"
    >
      <div v-if="fileList.length < 8">
        <a-icon type="plus"/>
        <div class="ant-upload-text">
          {{ $t('message.uploadPic') }}
        </div>
      </div>
    </a-upload>
  </a-card>
</template>
<script>

import { imgUpload } from '@/api/commonApi'

export default {
  name: 'PicDisplayListEdit',
  props: {
    picDisplayList: {
      type: Array
    },
    fieldName: {
      type: String
    }
  },
  data () {
    return {
      changed: false,
      fileList: []
    }
  },
  mounted () {

  },
  methods: {
    beforeUploadPicDisplayList (file) {
      return true
    },
    httpRequestPicDisplayList (data) {
      const formData = new FormData()
      if (data.file != null) {
        formData.append('file', data.file)
      }
      const that = this
      imgUpload(formData).then((res) => {
        console.log('上传成功', res)
        this.$notification['success']({
          message: '上传成功',
          description: res.url
        })
        if (!res.ratio) {
          res.ratio = '0.75'
        }
        that.fileList[that.fileList.length - 1].ratio = res.ratio
        that.fileList[that.fileList.length - 1].url = res.url
        that.fileList[that.fileList.length - 1].status = 'done'
        that.picDisplayList.push({ url: res.url, ratio: res.ratio })
      }).catch((res) => {
        console.log('上传失败', res)
        this.$notification['error']({
          message: '上传失败',
          description: res
        })
      })
    },
    // 图片上传
    handlePicDisplayListChange (info) {
      this.changed = true
      this.fileList = info.fileList
      this.picDisplayList.splice(0, this.picDisplayList.length)
      this.fileList.map((item, index, array) => {
        if (item.url && item.url !== '') {
          this.picDisplayList.push({ url: item.url, ratio: item.ratio })
        }
      })
      console.log('handlePicDisplayListChange', info, this.picDisplayList)
    },
    updatePicDisplayList () {
      this.picDisplayList.splice(0, this.picDisplayList.length)
      this.fileList.map((item, index, array) => {
        this.picDisplayList.push(item.url)
      })
      console.log('updatePicDisplayList', this.picDisplayList)
    }
  },
  watch: {
    picDisplayList (value) {
      if (!this.changed && this.picDisplayList && this.picDisplayList.length > 0) {
        this.picDisplayList.map((item, index) => {
          this.fileList.push({
            uid: index,
            name: `image${index}.png`,
            status: 'done',
            url: item.url
          })
        })
      }
    }
  }
}
</script>
<style>
.ant-select {
  width: 100%;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
