<template>
  <a-card :title="$t('field.'+fieldName)">
    <a-upload
      :before-upload="beforeUploadPic"
      :customRequest="httpRequestPicUrl"
      :file-list="picUrlList"
      list-type="picture-card"
      @change="handlePicDisplayUrlChange"
    >
      <div v-if="picUrlList.length < 1">
        <a-icon :type="loading ? 'loading' : 'plus'"/>
        <div class="ant-upload-text">
          {{ $t('message.uploadPic') }}
        </div>
      </div>
    </a-upload>
  </a-card>
</template>
<script>

import { imgUpload } from '@/api/commonApi'

export default {
  name: 'PicUrlEdit',
  props: {
    fieldName: {
      type: String
    },
    data: {
      type: Object
    }
  },
  data () {
    return {
      picUrl: {},
      picUrlList: [],
      loading: false
    }
  },
  mounted () {
    const value = this.data[this.fieldName]
    if (value) {
      this.picUrlList = [{
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: value.url
      }
      ]
    }
  },
  methods: {
    reset () {
      this.picUrlList = []
    },
    beforeUploadPic (file) {
      return true
    },
    httpRequestPicUrl (data) {
      const formData = new FormData()
      if (data.file != null) {
        formData.append('file', data.file)
      }
      const that = this
      imgUpload(formData).then((res) => {
        if (!res.ratio) {
          res.ratio = '0.75'
        }
        that.picUrl.uid = new Date().getTime()
        that.picUrl.url = res.url
        that.picUrl.ratio = res.ratio
        that.picUrl.status = 'done'
        that.data[this.fieldName] = { url: res.url, ratio: res.ratio }
        if (that.picUrlList.length === 0) {
          that.picUrlList.push(that.picUrl)
        } else {
          that.picUrlList[that.picUrlList.length - 1].url = res.url
          that.picUrlList[that.picUrlList.length - 1].ratio = res.ratio
          that.picUrlList[that.picUrlList.length - 1].status = 'done'
        }
        console.log('上传成功', res, that.picUrlList, that.picUrl)
        this.$notification['success']({
          message: '上传成功',
          description: res.url
        })
      }).catch((res) => {
        console.log('上传失败', res)
        this.$notification['error']({
          message: '上传失败',
          description: res
        })
      })
    },
    handlePicDisplayUrlChange (info) {
      console.log('handlePicDisplayUrlChange', info)
      this.picUrlList = info.fileList
      if (info.fileList.length == 0) {
        this.data[this.fieldName] = { url: '', ratio: '' }
      }
    }
  },
  computed: {
    picDisplayValue () {
      return this.data[this.fieldName]
    }
  },
  watch: {
    picDisplayValue (value) {
      if (value) {
        if (value.url !== '') {
          this.picUrlList = [{
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: value.url
          }
          ]
        } else {
          this.picUrlList = []
        }
      }
    }
  }
}
</script>
<style>
.ant-select {
  width: 100%;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
