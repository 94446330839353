<template>
  <div>
    <a-card :bordered="false">
      <a-tabs default-active-key="1">
        <a-tab-pane :forceRender="true" key="1" :tab="$t('title.basicInfo')">
          <a-descriptions bordered title="">
            <a-descriptions-item :label="$t('field.homeTemplateNo')">{{ data.homeTemplateNo }}</a-descriptions-item>
            <a-descriptions-item :label="$t('field.homeTemplateName')">{{ data.homeTemplateName }}</a-descriptions-item>
            <a-descriptions-item :label="$t('field.homeTemplateIntro')">{{ data.homeTemplateIntro }}</a-descriptions-item>
            <a-descriptions-item :label="$t('field.homeTemplateStatus')">{{ data.homeTemplateStatus }}
            </a-descriptions-item
            >
            <a-descriptions-item :label="$t('field.marketplaceCode')">{{ data.marketplaceName }}-{{ data.marketplaceCode }}
            </a-descriptions-item>
            <!--            <a-descriptions-item :label="$t('field.siteList')">-->
            <!--            </a-descriptions-item>-->
            <a-descriptions-item :label="$t('field.language')">{{ getCode('languageName', data.language) }}
            </a-descriptions-item>
          </a-descriptions>
          <br/>
          <a-descriptions bordered title="">
            <a-descriptions-item :label="$t('field.newUserPromptText')">{{ data.newUserPromptText }}</a-descriptions-item>
            <a-descriptions-item :label="$t('field.guidPageUrl')">{{ data.guidPageUrl }}</a-descriptions-item>
            <a-descriptions-item :label="$t('field.aboutUsPageUrl')">{{ data.aboutUsPageUrl }}</a-descriptions-item>
          </a-descriptions>
          <br/>
          <a-descriptions bordered title="">
            <a-descriptions-item label="创建人编码">{{ data.creatorCode }}</a-descriptions-item>
            <a-descriptions-item label="创建人">{{ data.creatorName }}</a-descriptions-item>
            <a-descriptions-item label="创建时间">{{ formatDateTime(data.createTime) }}</a-descriptions-item>
            <a-descriptions-item label="更新人编码">{{ data.updaterCode }}</a-descriptions-item>
            <a-descriptions-item label="更新人">{{ data.updaterName }}</a-descriptions-item>
            <a-descriptions-item label="更新时间">{{ formatDateTime(data.updateTime) }}</a-descriptions-item>
          </a-descriptions>
        </a-tab-pane>
        <a-tab-pane :forceRender="true" key="7" :tab="$t('title.bannerSetting')">
          <div class="title">
            <span>{{ this.$t('title.bannerList') }}</span>
          </div>
          <a-table
            v-if="data && data.bannerList.length>0"
            :columns="bannerListColumns"
            :dataSource="data.bannerList"
            :pagination="false"
            :bordered="true"
            :row-key="(record, index) => index"
            rowKey="bannerListKey"
          >
            <div slot="picUrl" slot-scope="text">
              <img :src="text" style="max-width:150px;max-height:100px"/>
            </div>
            <!-- <template slot="action" slot-scope="text, record">
              <a-popconfirm :title="$t('msg.confirmDelete')" @confirm="() => onDeleteBannerList(record)">
                <a href="javascript:;">{{ $t('button.delete') }}</a>
          </a-popconfirm>
        </template> -->
            <div slot="linkParamList" slot-scope="text, record" style="width: 100%;word-wrap:break-word;">
              <a-row :gutter="24">
                <a-col :md="24" :sm="24">
                  <div style="text-wrap: unrestricted" v-for="(item, index) in record.linkParamList" :key="index" >
                    {{ $t('field.'+item.linkParamName) }}:{{ item.linkParamValue }}
                  </div>
                </a-col>
              </a-row>
            </div>
          </a-table>
        </a-tab-pane>
        <a-tab-pane :forceRender="true" key="12" :tab="$t('title.picSetting')">
          <div :style="{marginBottom:'10px'}">
            <PicDisplayEdit :data="data" field-name="guideLinkPicDisplay"></PicDisplayEdit>
          </div>
          <div :style="{marginBottom:'10px'}">
            <PicDisplayEdit :data="data" field-name="couponPicDisplay"></PicDisplayEdit>
          </div>
          <div :style="{marginBottom:'10px'}">
            <PicDisplayEdit :data="data" field-name="couponInDetailPicDisplay"></PicDisplayEdit>
          </div>
          <div :style="{marginBottom:'10px'}">
            <PicDisplayEdit :data="data" field-name="getCouponPromptPicDisplay"></PicDisplayEdit>
          </div>
        </a-tab-pane>
        <a-tab-pane :forceRender="true" key="2" :tab="$t('title.couponSetting')">
          <div class="title">
            <span>{{ this.$t('title.couponList') }}</span>
          </div>
          <a-table
            v-if="data && data.couponList.length>0"
            :columns="couponListColumns"
            :dataSource="data.couponList"
            :pagination="false"
            :row-key="(record) => record.spuId"
            :bordered="true"
          >
            <!-- <template slot="action" slot-scope="text, record">
              <a-popconfirm :title="$t('msg.confirmDelete')" @confirm="() => onDeleteCouponList(record)">
                <a href="javascript:;">{{ $t('button.delete') }}</a>
          </a-popconfirm>
        </template> -->
          </a-table>
        </a-tab-pane>
        <a-tab-pane :forceRender="true" key="6" :tab="$t('title.noticeSetting')">
          <StringListEdit
            :editable="false"
            :value-list="data.topMessageList"
            field-name="topMessageList"/>
        </a-tab-pane>
        <a-tab-pane :forceRender="true" key="8" :tab="$t('title.topPicDisplayList')">
          <PicDisplayListEdit
            :pic-display-list="data.topPicDisplayList"
            field-name="topPicDisplayList"></PicDisplayListEdit>
        </a-tab-pane>
        <!--        <a-tab-pane :forceRender="true" key="3" :tab="$t('title.campaignSetting')">-->
        <!--          <div class="title">-->
        <!--            <span>{{ this.$t('title.campaignList') }}</span>-->
        <!--          </div>-->
        <!--          <a-table-->
        <!--            v-if="data && data.campaignList.length>0"-->
        <!--            :columns="campaignListColumns"-->
        <!--            :dataSource="data.campaignList"-->
        <!--            :pagination=" false"-->
        <!--            :bordered="true"-->
        <!--            :row-key="(record, index) => index"-->
        <!--          >-->
        <!--          </a-table>-->
        <!--        </a-tab-pane>-->
        <!--        <a-tab-pane :forceRender="true" key="4" :tab="$t('title.goodsSetting')">-->
        <!--          <div class="title">-->
        <!--            <span class="title">spu列表 </span>-->
        <!--          </div>-->
        <!--          <a-table-->
        <!--            v-if="data.spuList && data.spuList.length>0"-->
        <!--            :columns="spuListColumns"-->
        <!--            :dataSource="data.spuList"-->
        <!--            :pagination="false"-->
        <!--            :bordered="true"-->
        <!--            :row-key="(record) => record.couponId"-->
        <!--          >-->
        <!--            <div slot="listPicUrl" slot-scope="text,record">-->
        <!--              <img v-if="text" :src="text" style="max-height: 100px"/>-->
        <!--              <div v-else>-->
        <!--                <img-->
        <!--                  v-if="record.picList && record.picList.length>0"-->
        <!--                  :src="record.picList[0]"-->
        <!--                  style="max-height: 100px"/>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </a-table>-->
        <!--          <a-divider style="margin-bottom: 32px"/>-->
        <!--          <div class="title">-->
        <!--            <span class="title">sku列表 </span>-->
        <!--          </div>-->
        <!--          <a-table-->
        <!--            v-if="data.skuList && data.skuList.length>0"-->
        <!--            :columns="skuListColumns"-->
        <!--            :dataSource="data.skuList"-->
        <!--            :pagination="false"-->
        <!--            :bordered="true"-->
        <!--            :row-key="(record) => record.skuId"-->
        <!--          >-->
        <!--            <div slot="listPicUrl" slot-scope="text,record">-->
        <!--              <img v-if="text" :src="text" style="max-height: 100px"/>-->
        <!--              <div v-else>-->
        <!--                <img-->
        <!--                  v-if="record.picList && record.picList.length>0"-->
        <!--                  :src="record.picList[0]"-->
        <!--                  style="max-height: 100px"/>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </a-table>-->
        <!--        </a-tab-pane>-->
        <a-tab-pane :forceRender="true" key="5" :tab="$t('title.approvalHistoryList')">
          <ApprovalHistoryList :approval-history-list="data.approvalInfoList"/>
        </a-tab-pane>
      </a-tabs>
      <a-divider type="horizontal"/>
      <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
        <a-button type="primary" style="margin-left: 8px" @click="handlePreview" icon="play-circle">
          {{ $t('button.preview') }}
        </a-button>
      </a-form-item>
      <PhonePreview ref="phonePreview"/>
    </a-card>
  </div>
</template>

<script>
import { STable } from '@/components'
import { getHomeTemplateData } from '@/api/cms/homeTemplate'
import ApprovalHistoryList from '@/components/approval/ApprovalHistoryList'
import PhonePreview from '@/views/component/PhonePreview'
import StringListEdit from '@/views/component/StringListEdit'
import PicDisplayListEdit from '@/views/component/PicDisplayListEdit'
import PicDisplayEdit from '@/views/component/PicDisplayEdit'
export default {
  name: 'SkuDisplayDetail',
  components: {
    STable,
    PhonePreview,
    ApprovalHistoryList,
    StringListEdit,
    PicDisplayListEdit,
    PicDisplayEdit
  },
  props: {
    options: {
      type: Object
    }
  },
  created () {
    // getRoleList({t: new Date()})
  },
  data () {
    return {
      queryParam: {},
      data: {
        spuList: [],
        skuList: [],
        campaignList: [],
        couponList: [],
        bannerList: []
      },
      bannerListColumns: [
        {
          defaultTitle: '连接目标类型',
          title: this.$t('columnName.linkTargetType'),
          dataIndex: 'linkTargetType',
          key: 'linkTargetType',
          align: 'left',
          scopedSlots: {
            customRender: 'linkTargetType'
          }
        },
        {
          defaultTitle: '图片URL',
          title: this.$t('columnName.picUrl'),
          dataIndex: 'picUrl',
          key: 'picUrl',
          align: 'left',
          scopedSlots: {
            customRender: 'picUrl'
          }
        },
        {
          defaultTitle: '链接参数',
          title: this.$t('columnName.linkParamList'),
          dataIndex: 'linkParamList',
          key: 'linkParamList',
          align: 'left',
          scopedSlots: {
            customRender: 'linkParamList'
          }
        }

        // {
        //   defaultTitle: '操作',
        //   title: this.$t('button.action'),
        //   dataIndex: 'action',
        //   key: 'action',
        //   align: 'left',
        //   scopedSlots: {
        //     customRender: 'action'
        //   }
        // }
      ],
      couponListColumns: [
        {
          defaultTitle: '优惠券码',
          title: this.$t('columnName.couponCode'),
          dataIndex: 'couponCode',
          key: 'couponCode',
          align: 'left',
          scopedSlots: {
            customRender: 'couponCode'
          }
        },
        {
          defaultTitle: '图片URL',
          title: this.$t('columnName.picUrl'),
          dataIndex: 'picUrl',
          key: 'picUrl',
          align: 'left',
          scopedSlots: {
            customRender: 'picUrl'
          }
        }
        // {
        //   defaultTitle: '操作',
        //   title: this.$t('button.action'),
        //   dataIndex: 'action',
        //   key: 'action',
        //   align: 'left',
        //   scopedSlots: {
        //     customRender: 'action'
        //   }
        // }
      ],
      campaignListColumns: [
        {
          defaultTitle: '促销编码',
          title: this.$t('columnName.campaignCode'),
          dataIndex: 'campaignCode',
          key: 'campaignCode',
          align: 'left',
          scopedSlots: {
            customRender: 'campaignCode'
          }
        },
        {
          defaultTitle: '促销名称',
          title: this.$t('columnName.campaignName'),
          dataIndex: 'campaignName',
          key: 'campaignName',
          align: 'left',
          scopedSlots: {
            customRender: 'campaignName'
          }
        },
        {
          defaultTitle: '营销活动说明',
          title: this.$t('columnName.campaignDesc'),
          dataIndex: 'campaignDesc',
          key: 'campaignDesc',
          align: 'left',
          scopedSlots: {
            customRender: 'campaignDesc'
          }
        },
        {
          defaultTitle: '图片URL',
          title: this.$t('columnName.picUrl'),
          dataIndex: 'picUrl',
          key: 'picUrl',
          align: 'left',
          scopedSlots: {
            customRender: 'picUrl'
          }
        }
        // {
        //   defaultTitle: '操作',
        //   title: this.$t('button.action'),
        //   dataIndex: 'action',
        //   key: 'action',
        //   align: 'left',
        //   scopedSlots: {
        //     customRender: 'action'
        //   }
        // }
      ],
      skuListColumns: [
        {
          defaultTitle: 'SKU编号',
          title: this.$t('columnName.skuNo'),
          dataIndex: 'skuNo',
          key: 'skuNo',
          align: 'left',
          scopedSlots: {
            customRender: 'skuNo'
          }
        },
        {
          defaultTitle: 'SKU名称',
          title: this.$t('columnName.skuName'),
          dataIndex: 'skuName',
          key: 'skuName',
          align: 'left',
          scopedSlots: {
            customRender: 'skuName'
          }
        },
        {
          defaultTitle: '列表图片URL',
          title: this.$t('columnName.listPicUrl'),
          dataIndex: 'listPicUrl',
          key: 'listPicUrl',
          align: 'left',
          scopedSlots: {
            customRender: 'listPicUrl'
          }
        }
        // {
        //   defaultTitle: '操作',
        //   title: this.$t('button.action'),
        //   dataIndex: 'action',
        //   key: 'action',
        //   align: 'left',
        //   scopedSlots: {
        //     customRender: 'action'
        //   }
        // }
      ],
      spuListColumns: [
        {
          defaultTitle: 'SPU编码',
          title: this.$t('columnName.spuNo'),
          dataIndex: 'spuNo',
          key: 'spuNo',
          align: 'left',
          scopedSlots: {
            customRender: 'spuNo'
          }
        },
        {
          defaultTitle: 'SPU名称',
          title: this.$t('columnName.spuName'),
          dataIndex: 'spuName',
          key: 'spuName',
          align: 'left',
          scopedSlots: {
            customRender: 'spuName'
          }
        },
        {
          defaultTitle: '列表图片URL',
          title: this.$t('columnName.listPicUrl'),
          dataIndex: 'listPicUrl',
          key: 'listPicUrl',
          align: 'left',
          scopedSlots: {
            customRender: 'listPicUrl'
          }
        }
        // {
        //   defaultTitle: '操作',
        //   title: this.$t('button.action'),
        //   dataIndex: 'action',
        //   key: 'action',
        //   align: 'left',
        //   scopedSlots: {
        //     customRender: 'action'
        //   }
        // }
      ],
      codeList: {
        categoryCode: [],
        brandCode: [],
        status: [],
        isValid: []
      }
    }
  },
  mounted () {
    this.loadData({})
  },
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData (parameter) {
      let params = { homeTemplateId: this.options.homeTemplateId }
      if (parameter && parameter.homeTemplateId) {
        params = parameter
      }
      getHomeTemplateData(params).then((res) => {
        console.log('loadData->getHomeTemplateData.parameter', parameter, res)
        this.data = res
        return this.data
      })
    },
    /**
     * 预览
     */
    handlePreview () {
      this.$refs.phonePreview.show('/#/?homeTemplateId=' + this.data.homeTemplateId)
    }
  },
  filters: {
    statusFilter (status) {
      const statusMap = {
        processing: '进行中',
        success: '完成',
        failed: '失败'
      }
      return statusMap[status]
    }
  },
  computed: {
    title () {
      return this.$route.meta.title
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}
</style>
