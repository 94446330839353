<template>
  <!-- 两步验证 -->
  <a-modal
    centered
    :title="$t('page.commentEditor')"
    v-model="visible"
    @ok="handleOk"
    @cancel="handleCancel"
    :maskClosable="false"
  >
    <a-textarea placeholder="请输入备注" :rows="4" :maxLength="300" v-model="comment"/>
  </a-modal>
</template>

<script>
export default {
  props: {
    record: {
      type: Object
    },
    type: {
      type: String
    }
  },
  data () {
    return {
      visible: false,
      comment: ''
    }
  },
  methods: {
    show (record, type) {
      this.comment = ''
      this.record1 = record
      this.type1 = type
      this.visible = true
      console.log('commentEditorShow', this.record1, this.type1)
    },
    handleOk () {
      const that = this
      if (this.comment.trim() === '') {
        this.$confirm({
          title: '备注信息为空，确认提交?',
         // content: content,
          onOk () {
            console.log('OK')
            that.record1[that.type1 + 'Comment'] = that.comment
            that.record1.comment = that.comment
            that.visible = false
            that.$emit('handleOk', { type: that.type1, record: that.record1 })
          },
          onCancel () {
            console.log('Cancel')
          }
        })
      } else {
        this.record1[this.type1 + 'Comment'] = this.comment
        this.record1.comment = this.comment
        this.visible = false
        this.$emit('handleOk', { type: this.type1, record: this.record1 })
      }
    },
    handleCancel () {
      this.visible = false
      this.$emit('cancel')
    },
    onForgeStepCode () {

    }
  }
}
</script>
<style lang="less" scoped>
.step-form-wrapper {
  margin: 0 auto;
  width: 80%;
  max-width: 400px;
}
</style>
