import request from '@/utils/request'

const baseUrl = '/api'

export function saveHomeTemplateData (parameter) {
  let url = '/cms/homeTemplate/save'
  if (!parameter.homeTemplateId) {
    url = '/cms/homeTemplate/create'
  }
  return request({
    url: baseUrl + url,
    method: 'post',
    data: parameter
  }).then(res => {
    let data = res.data
    if (!data) {
      data = res.data
    }
    return data
  })
}

export function searchHomeTemplate (parameter) {
  return request({
    url: baseUrl + '/cms/homeTemplate/search',
    method: 'post',
    data: parameter
  }).then(res => {
    let data = res.data
    if (!data) {
      data = res.data
    }
    return {
      data: data.list,
      pageNo: data.pageInfo.pageNo ? data.pageInfo.pageNo : 1,
      totalCount: data.pageInfo.totalCount
    }
  })
}

export function listHomeTemplate (parameter) {
  return request({
    url: baseUrl + '/cms/homeTemplate/list',
    method: 'post',
    data: parameter
  }).then(res => {
    let data = res.data
    if (!data) {
      data = res.data
    }
    return {
      data: data
    }
  })
}

export function getHomeTemplateData (parameter) {
  return request({
    url: baseUrl + '/cms/homeTemplate/get',
    method: 'post',
    data: parameter
  }).then(res => {
    let data = res.data
    if (!data) {
      data = res.data
    }
    return data
  })
}

export function enableHomeTemplate (parameter) {
  return request({
    url: baseUrl + '/cms/homeTemplate/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    let data = res.data
    if (!data) {
      data = res.data
    }
    return data
  })
}

export function publishHomeTemplate (parameter) {
  return request({
    url: baseUrl + '/cms/homeTemplate/publish',
    method: 'post',
    data: parameter
  }).then(res => {
    let data = res.data
    if (!data) {
      data = res.data
    }
    return data
  })
}

export function approvalHomeTemplate (parameter) {
  return request({
    url: baseUrl + '/cms/homeTemplate/approval',
    method: 'post',
    data: parameter
  }).then(res => {
    let data = res.data
    if (!data) {
      data = res.data
    }
    return data
  })
}

export function disableHomeTemplate (parameter) {
  return request({
    url: baseUrl + '/cms/homeTemplate/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    let data = res.data
    if (!data) {
      data = res.data
    }
    return data
  })
}
